// 文化 - 动态
import { CULTURE } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询新闻列表
export async function getNewsListApi(params) {
  return request(`${CULTURE}/news/getNewsList`, METHOD.GET, params)
}

// 查询新闻详情
export async function getNewsDetailApi(params) {
  return request(`${CULTURE}/news/getNewsDetail`, METHOD.GET, params)
}

// 添加新闻
export async function addNewsApi(params) {
  return request(`${CULTURE}/news/addNews`, METHOD.POST, params)
}

// 删除新闻
export async function delNewsApi(params) {
  return request(`${CULTURE}/news/delNews`, METHOD.POST, params)
}
